import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '../../store';
import { HolidayTheme } from '../models/holiday-themes.model';
import { CoreState } from './core.reducer';

const coreState = (state: AppState): CoreState => state.core;

const isLoading = createSelector(coreState, (state) => state.isLoading);

const showCustomerReviewModal = createSelector(coreState, (state) => state.showCustomerReviewModal);

const isFooterVisible = createSelector(coreState, (state) => state.isFooterVisible);

const isUserLoggedIn = createSelector(coreState, (state) => state.user.isUserLoggedIn);

const isNavigationTabsVisible: MemoizedSelector<AppState, boolean> = createSelector(
  coreState,
  (state) => state.isNavigationTabsVisible && state.user.isUserLoggedIn,
);

const isSubmittingUserInteraction: MemoizedSelector<AppState, boolean> = createSelector(
  coreState,
  (state) => state.isSubmittingUserInteraction,
);

const getUserType = createSelector(coreState, (state) => state.user.userType);

const getIsAfterPurchase: MemoizedSelector<AppState, boolean> = createSelector(coreState, (state) => state.isAfterPurchase);

const getHolidayTheme: MemoizedSelector<AppState, HolidayTheme> = createSelector(coreState, (state) => state.holidayTheme);

const isLoadingHolidayTheme: MemoizedSelector<AppState, boolean> = createSelector(coreState, (state) => state.isLoadingHolidayTheme);

const isLandedWithOperation: MemoizedSelector<AppState, boolean> = createSelector(coreState, (state) => state.isLandedWithOperation);

export const coreSelectors = {
  isLoading,
  isFooterVisible,
  getIsAfterPurchase,
  isUserLoggedIn,
  getUserType,
  isNavigationTabsVisible,
  getHolidayTheme,
  isLoadingHolidayTheme,
  isSubmittingUserInteraction,
  isLandedWithOperation,
  showCustomerReviewModal,
};
