import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Subscription } from 'rxjs';

import { ZendeskService } from '../../../core/services/zendesk.service';
import { coreSelectors } from '../../../core/store/core.selectors';
import { AppState } from '../../../store';
import { ChatStates } from '../../enums/chat-states.enum';
import { ChatWidgetConfig } from '../../models/chat-button-config-model';
import { ChatbotService } from '../../services/chatbot.service';

@Component({
  selector: 'ni-chatbot-widget',
  templateUrl: './chatbot-widget.component.html',
  styleUrls: ['./chatbot-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('chatWindowAnimation', [
      transition(':enter', [style({ transform: 'translateX(100%)' }), animate('0.6s ease-out', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('0.6s ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
})
export class ChatbotWidgetComponent implements OnInit, OnDestroy {
  chatStateSubscription: Subscription = Subscription.EMPTY;
  isOpen: boolean;
  chatState: ChatStates;
  ChatStates = ChatStates;
  @Input() config: ChatWidgetConfig;

  constructor(
    private chatbotService: ChatbotService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private router: Router,
    private zendeskService: ZendeskService,
  ) {}

  ngOnInit(): void {
    this.chatStateSubscription = this.chatbotService.chatbotState$.subscribe((state) => {
      this.chatState = state;
      this.cdr.detectChanges();
    });
    this.registerVisibilityChangesEvents();
  }

  ngOnDestroy(): void {
    this.chatStateSubscription.unsubscribe();
  }

  chatButtonClicked(): void {
    if (this.zendeskService.isChatting()) {
      this.zendeskService.continueChat();
    } else {
      this.chatbotService.openChatbot(this.config.agentType, this.config.isPersistent, this.config.getParams());
    }
  }

  closeChatbot(): void {
    this.chatbotService.closeChatbot();
  }

  private registerVisibilityChangesEvents(): void {
    combineLatest([
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
      this.store.select(coreSelectors.isNavigationTabsVisible),
      this.store.select(coreSelectors.isLoading),
    ]).subscribe(([, isNavigationTabsVisible, isLoading]: [Event | RouterEvent, boolean, boolean]) => {
      if (!isNavigationTabsVisible && !isLoading) {
        this.closeChatbot();
      }
    });
  }
}
