<div [loading]="isLoading$ | async" niSpinner>
  <div
    [niHelpTip]="HelpTip.AddPolicy"
    class="container"
    *ngIf="policies.length || isHealthCrossSellVisible"
    data-test="cross-sell-policies-container"
  >
    <ni-cross-sell-policies-title
      [isHistoricalUser]="isHistoricalUser"
      [policies]="policies"
      *ngIf="showTitle"
    ></ni-cross-sell-policies-title>
    <div data-test="cross-sell-policies" class="cross-sell-policies">
      @if (isHealthCrossSellVisible) {
        <ni-health-insurance-cross-sell-policy class="cross-sell-policy" data-test="health-cross-sell" />
      }
      <ni-cross-sell-policy
        *ngFor="let policy of policies | crossSellPoliciesOrder"
        [niHelpTip]="HelpTip.CrossSellNotification"
        [policy]="policy"
        class="cross-sell-policy"
        data-test="cross-sell-policy"
      >
      </ni-cross-sell-policy>
    </div>
  </div>
</div>
