import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HelpTip } from '../../../core/models/help-tip.enum';
import { AppState } from '../../../store';
import { CrossSellPolicy } from '../../models/cross-sell-policy.model';
import { CrossSellPolicyStatus } from '../../models/cross-sell-policy-status.enum';
import { CrossSellService } from '../../services/cross-sell.service';
import { crossSellSelectors } from '../../store/cross-sell.selectors';

@Component({
  selector: 'ni-cross-sell-policies',
  templateUrl: './cross-sell-policies.component.html',
  styleUrls: ['./cross-sell-policies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossSellPoliciesComponent implements OnInit, OnChanges {
  @Input() policies: CrossSellPolicy[];
  @Input() isHistoricalUser: boolean;
  @Input() showTitle = false;
  HelpTip = HelpTip;
  isLoading$: Observable<boolean>;
  hasTwoOrMoreAvailablePolicies: boolean;
  isHealthCrossSellVisible: boolean;

  private store = inject<Store<AppState>>(Store);
  crossSellService = inject(CrossSellService);

  ngOnInit(): void {
    this.isLoading$ = this.store.select(crossSellSelectors.isLoading);
    this.isHealthCrossSellVisible = this.crossSellService.isHealthCrossSellVisible();
    this.hasTwoOrMoreAvailablePolicies = this.getHasTwoOrMoreAvailablePolicies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.policies) {
      this.hasTwoOrMoreAvailablePolicies = this.getHasTwoOrMoreAvailablePolicies();
    }
  }

  private getHasTwoOrMoreAvailablePolicies(): boolean {
    return this.policies.filter((policy) => policy.status !== CrossSellPolicyStatus.CurrentlyNotAvailable).length >= 2;
  }
}
