import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NiButtonRbModule, NiSwitchRbModule } from '@next-insurance/ni-material';
import { TranslateModule } from '@ngx-translate/core';

import { HelpTipDirective } from '../shared/directives/help-tip.directive';
import { SpinnerDirective } from '../shared/directives/spinner.directive';
import { LobNamePipe } from '../shared/pipes/lob-name.pipe';
import { CrossSellPoliciesComponent } from './components/cross-sell-policies/cross-sell-policies.component';
import { CrossSellPolicyComponent } from './components/cross-sell-policies/cross-sell-policy/cross-sell-policy.component';
import { HealthInsuranceCrossSellPolicyComponent } from './components/cross-sell-policies/health-insurance-cross-sell-policy/health-insurance-cross-sell-policy.component';
import { CrossSellPoliciesTitleComponent } from './components/cross-sell-policies-title/cross-sell-policies-title.component';
import { CrossSellPoliciesOrder } from './pipes/cross-sell-policies-order.pipe';
import { CrossSellPolicyDescriptionPipe } from './pipes/cross-sell-policy-description.pipe';
import { CrossSellTrackingService } from './services/cross-sell-tracking.service';

const declarations = [
  CrossSellPoliciesOrder,
  CrossSellPolicyDescriptionPipe,
  CrossSellPoliciesTitleComponent,
  CrossSellPoliciesComponent,
  CrossSellPolicyComponent,
];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    NiSwitchRbModule,
    SpinnerDirective,
    LobNamePipe,
    HelpTipDirective,
    NiButtonRbModule,
    TranslateModule,
    HealthInsuranceCrossSellPolicyComponent,
  ],
  exports: [...declarations],
  providers: [CrossSellTrackingService],
})
export class CrossSellModule {}
