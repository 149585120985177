import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@next-insurance/ng-core';
import { addHours } from 'date-fns';
import { nanoid } from 'nanoid';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';

const AUTH0_URL = environment.nextLoginAuthenticationIssuer;
const CLIENT_ID = environment.authenticationIssuerClientId;

@Injectable({
  providedIn: 'root',
})
export class Auth0UtilsService {
  private window = inject(WINDOW);
  private cookieService = inject(CookieService);

  createLogoutURL(): string {
    const paramsString = new URLSearchParams({
      client_id: CLIENT_ID,
      returnTo: `${this.window.location.origin}/public/next-login/post-logout-callback`,
    }).toString();
    return `${AUTH0_URL}v2/logout?${paramsString}`;
  }

  async createLoginURL(): Promise<string> {
    const { verifier, portalLoginURL } = await this.generateNewLoginUrl();
    this.setVerifierCookieForPortal(verifier);

    return portalLoginURL;
  }

  private setVerifierCookieForPortal(verifier: string): void {
    const oneHour = addHours(new Date(), 1);
    const options: CookieOptions = {
      expires: oneHour,
      domain: environment.host,
      secure: true,
      sameSite: environment.production ? 'Strict' : 'None',
      path: '/api/public/authentication/exchange-code-for-tokens',
    };
    this.cookieService.set(environment.loginCodeVerifierCookieName, verifier, options);
  }

  private async generateNewLoginUrl(): Promise<{ verifier: string; portalLoginURL: string }> {
    const { verifier, challenge } = await this.generateVerifierChallengePair();
    const nextLoginUrl = `${AUTH0_URL}authorize`;
    const params = new URLSearchParams({
      client_id: CLIENT_ID,
      scope: `openid profile email offline_access`,
      redirect_uri: `${environment.url}/public/next-login/post-login-callback`,
      audience: 'http://nextinsurance.com/be_api',
      response_type: 'code',
      code_challenge: challenge,
      code_challenge_method: 'S256',
    });
    const portalLoginURL = `${nextLoginUrl}?${params.toString()}`;
    return { verifier, portalLoginURL };
  }

  private async generateVerifierChallengePair(): Promise<{ verifier: string; challenge: string }> {
    const verifier = this.base64URLEncode(nanoid(43));
    const challenge = await this.generateChallenge(verifier);
    return { verifier, challenge };
  }

  private base64URLEncode(str: string): string {
    return str.toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private async generateChallenge(text: string): Promise<string> {
    const encoder = new TextEncoder();
    const { buffer } = encoder.encode(text);
    const hashBuffer = await window.crypto.subtle.digest({ name: 'SHA-256' }, buffer);
    const hashArray = new Uint8Array(hashBuffer);
    const hashBase64 = btoa(String.fromCharCode.apply(null, hashArray));
    return this.base64URLEncode(hashBase64);
  }
}
