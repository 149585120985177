import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonType, NiButtonRbModule } from '@next-insurance/ni-material';
import { TranslateModule } from '@ngx-translate/core';

import { LobNamePipe } from '../../../../shared/pipes/lob-name.pipe';

@Component({
  selector: 'ni-health-insurance-cross-sell-policy',
  templateUrl: './health-insurance-cross-sell-policy.component.html',
  styleUrls: ['./health-insurance-cross-sell-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LobNamePipe, NgIf, NiButtonRbModule, TranslateModule],
})
export class HealthInsuranceCrossSellPolicyComponent {
  protected readonly ButtonType = ButtonType;

  openHealthInsuranceModal(): void {
    // TBD
  }
}
