<form [formGroup]="form" class="payment-form p-sm_md" novalidate>
  <div class="credit-card-form-wrapper" data-test="credit-card-wrapper">
    <div class="form-controls-row">
      <div class="form-control-row name-on-card">
        <label class="ni-input-label-rb">
          {{ 'PAYMENT.PAYMENT_FORM.PAYMENT_NAME' | translate }}
        </label>
        <input
          autocomplete="cc-name"
          class="ni-input-rb"
          data-test="name-on-card"
          formControlName="name"
          id="nameOnCard"
          niTrim
          placeholder="{{ 'PAYMENT.PAYMENT_FORM.FIRST_LAST' | translate }}"
          required
          type="text"
        />
      </div>
      <div class="form-control-row">
        <label class="ni-input-label-rb">
          {{ 'PAYMENT.PAYMENT_FORM.CARD_NUMBER' | translate }}
        </label>
        <div class="ni-input-rb" data-test="card-number" id="cardNumber">
          <!-- a Stripe Element will be inserted here. -->
        </div>
        <div *ngIf="errors.cardNumber" class="form-error-message" data-test="error-card-number">{{ errors.cardNumber }}</div>
      </div>
      <div class="form-control same-line">
        <div class="form-control-row exp-date">
          <label class="ni-input-label-rb">
            {{ 'PAYMENT.PAYMENT_FORM.EXPIRATION_DATE' | translate }}
          </label>
          <div data-test="card-expiration" id="cardExpiration">
            <!-- a Stripe Element will be inserted here. -->
          </div>
          <div *ngIf="errors.cardExpiry" class="form-error-message" data-test="error-card-expiry">{{ errors.cardExpiry }}</div>
        </div>
        <div class="form-control-row">
          <label class="ni-input-label-rb">
            <span>{{ 'PAYMENT.PAYMENT_FORM.SECURITY_CODE' | translate }}</span>
          </label>
          <div data-test="cvc" id="cardSecurityCode">
            <!-- a Stripe Element will be inserted here. -->
          </div>
          <div *ngIf="errors.cardCvc" class="form-error-message" data-test="error-card-cvc">{{ errors.cardCvc }}</div>
        </div>
      </div>
      <div class="form-control-row credit-card-footer">
        <span class="secure-text p-xs_sm secure-icon">{{ 'PAYMENT.PAYMENT_FORM.SECURE_PAYMENT' | translate }}</span>
        <ni-credit-card-icons [selectedCardType]="selectedCardType"></ni-credit-card-icons>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <ni-button-rb
      (click)="submit()"
      [disabled]="!!(errors.cardNumber || errors.cardExpiry || errors.cardCvc || isConfirmingSetupIntent || form.invalid)"
      [isLoading]="isSubmitting"
      data-test="payment-update-submit"
      id="submit"
    >
      {{ submitText || 'GENERAL.SUBMIT' | translate }}
    </ni-button-rb>
  </div>
</form>
