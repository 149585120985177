import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { NIError } from '@next-insurance/errors';
import logger from '@next-insurance/logger';
import { AchPaymentFormModule, NiButtonRbModule, StripeAchService } from '@next-insurance/ni-material';
import { AchPaymentFormViewOptions } from '@next-insurance/ni-material/lib/modules/ach-payment/components/ach-payment-form/ach-payment-form-view-options.model';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SetupIntent } from '@stripe/stripe-js';
import { TooltipModule } from 'primeng/tooltip';
import { first } from 'rxjs/operators';

import { businessSelectors } from '../../../business/store/business.selectors';
import { TooltipPosition } from '../../../core/models/tooltip-position.enum';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { TooltipDirective } from '../../../shared/directives/tooltip.directive';
import { AppState } from '../../../store';

@Component({
  selector: 'ni-stripe-ach-payment-form',
  templateUrl: './stripe-ach-payment-form.component.html',
  styleUrls: ['./stripe-ach-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, LoaderComponent, NgIf, TranslateModule, AchPaymentFormModule, NiButtonRbModule, TooltipModule, TooltipDirective],
})
export class StripeAchPaymentFormComponent implements OnInit {
  providerToken = input.required<string>();
  @Input() isSubmitting: boolean;
  @Input() shouldDisplayTitle: boolean;
  @Output() submitted = new EventEmitter<string>();
  @Output() error = new EventEmitter<string>();
  isConfirmingSetupIntent: boolean;
  isFormCompleted: boolean;
  isFormReady: boolean;
  private emailAddress: string;
  protected readonly achPaymentViewOptions: AchPaymentFormViewOptions = {
    wrapSection: true,
    securelyConnectionText: this.translate.instant('PAYMENT.PAYMENT_FORM.ACH.SECURELY_CONNECTION'),
  };

  protected readonly TooltipPosition = TooltipPosition;

  constructor(
    private stripeAchService: StripeAchService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.emailAddress = this.getEmailAddress();
  }

  onFormReady(): void {
    this.isFormReady = true;
    this.cdr.markForCheck();
  }

  onFormCompleted(): void {
    this.isFormCompleted = true;
    this.cdr.markForCheck();
  }

  onFormNotCompleted(): void {
    this.isFormCompleted = false;
    this.cdr.markForCheck();
  }

  async onSubmit(): Promise<void> {
    try {
      this.setIsConfirmingSetupIntent(true);
      const setupIntent = await this.stripeAchService.confirmSetupIntent(this.emailAddress);
      this.setIsConfirmingSetupIntent(false);
      if (this.validateSetupIntent(setupIntent)) {
        this.submitted.emit(setupIntent.payment_method as string);
      } else {
        this.error.emit();
      }
    } catch (error) {
      if (error instanceof NIError) {
        logger.error('StripeAchPaymentFormComponent | onSubmit: Could not confirm setup intent with Stripe API', error, error.extra);
        this.setIsConfirmingSetupIntent(false);
        this.error.emit(error.message);
      }
    }
  }

  private setIsConfirmingSetupIntent(isConfirmingSetupIntent: boolean): void {
    this.isConfirmingSetupIntent = isConfirmingSetupIntent;
    this.cdr.markForCheck();
  }

  private validateSetupIntent(setupIntent: SetupIntent): boolean {
    if (!setupIntent) {
      logger.error('StripeAchPaymentFormComponent: setupIntent is not defined');
      return false;
    }

    if (typeof setupIntent.payment_method !== 'string') {
      logger.error('StripeAchPaymentFormComponent: setupIntent.payment_method is not a string');
      return false;
    }

    return true;
  }

  private getEmailAddress(): string {
    let emailAddress: string;
    this.store
      .select(businessSelectors.getEmailAddress)
      .pipe(first())
      .subscribe((email) => {
        emailAddress = email;
      });
    return emailAddress;
  }
}
