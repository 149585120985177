import { Auth0ErrorStatus } from './auth0-error-status.enum';

// todo: need to change AuthErrorStatuses to BEAuthErrorStatus
export enum AuthErrorStatuses {
  UserNotFound = 'USER_NOT_FOUND',
  TooManyAttempts = 'TOO_MANY_ATTEMPTS',
  WrongIdentityOrOtp = 'WRONG_IDENTITY_OR_OTP',
  InvalidOtpVerificationCode = 'INVALID_OTP_VERIFICATION_CODE',
  UnsubscribedSmsRecipient = 'UNSUBSCRIBED_SMS_RECIPIENT',
  VerificationCodeExpired = 'VERIFICATION_CODE_EXPIRED',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  Auth0InternalError = 'AUTH0_INTERNAL_ERROR',
  InvalidOtp = 'INVALID_OTP',
  InvalidIdentity = 'INVALID_IDENTITY',
  NotAuthorized = 'NOT_AUTHORIZED',
  TokenExpired = 'TOKEN_EXPIRED',
  BusinessNotFound = 'BUSINESS_NOT_FOUND',
  MoreThanOneBusinessFound = 'MORE_THAN_ONE_BUSINESS_FOUND',
  SupportedBusinessNotFound = 'SUPPORTED_BUSINESS_NOT_FOUND',
  NotSupportedInPortal = 'NOT_SUPPORTED_IN_PORTAL',
  BusinessHasNoPoliciesToDisplay = 'BUSINESS_HAS_NO_POLICIES_TO_DISPLAY',
  InternalFailure = 'INTERNAL_FAILURE',
  UnverifiedUserFailure = 'UNVERIFIED_PHONE_FAILURE',
  TooManyLoginAttempts = 'TOO_MANY_LOGIN_ATTEMPTS',
  RecaptchaServerError = 'RECAPTCHA_SERVER_ERROR',
  RecaptchaClientError = 'RECAPTCHA_CLIENT_ERROR',
  PhoneNumberNotExist = 'PHONE_NUMBER_NOT_EXIST',
  InputValidationFailure = 'INPUT_VALIDATION_FAILURE',
  PhoneTooManyAttempts = 'TOO_MANY_ATTEMPTS_SMS',
  EmailTooManyAttempts = 'TOO_MANY_ATTEMPTS_EMAIL',
  GoogleLoginFailed = 'GOOGLE_LOGIN_FAILED',
  OktaLoginFailed = 'OKTA_LOGIN_FAILED',
  UserBlocked = 'USER_BLOCKED',
}

export type AuthErrorStatus = AuthErrorStatuses | Auth0ErrorStatus;
