import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../core/services/auth.service';
import { LoginResponse } from '../models/login-response.model';
import { SendOtpResponse } from '../models/send-otp-response.model';

@Injectable({
  providedIn: 'root',
})
export class LoginV2DataService {
  constructor(private httpClient: HttpClient) {}

  /* istanbul ignore next */
  logout(): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/logout/v2', {});
  }

  /* istanbul ignore next */
  validateOtp(identityValue: string, otp: string): Observable<LoginResponse> {
    const identityType = AuthService.getIdentityType(identityValue);
    return this.httpClient.post<LoginResponse>('/api/public/authentication/validate-otp/v2', {
      identityValue,
      identityType,
      otp,
    });
  }

  /* istanbul ignore next */
  sendToken(identityValue: string, recaptchaToken: string): Observable<SendOtpResponse> {
    const identityType = AuthService.getIdentityType(identityValue);
    return this.httpClient.post<SendOtpResponse>('/api/public/authentication/send-otp/v2', {
      identityValue,
      identityType,
      recaptchaToken,
    });
  }

  /* istanbul ignore next */
  googleSsoCallback(code: string, googleError: string): Observable<void> {
    return this.httpClient.post<void>('/api/public/authentication/google-sso-callback', {
      code,
      googleError,
    });
  }

  /* istanbul ignore next */
  exchangeCodeForTokens(code: string): Observable<void> {
    return this.httpClient.get<void>(`/api/public/authentication/exchange-code-for-tokens?code=${code}`);
  }
}
