import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { PortalAuthService } from '../services/portal-auth.service';

export const loginCallbackGuard: CanActivateFn = (route) => {
  const portalAuthService = inject(PortalAuthService);
  portalAuthService.handleCallback(route.queryParams);
  return false;
};

export const logoutCallbackGuard: CanActivateFn = async () => {
  const portalAuthService = inject(PortalAuthService);
  await portalAuthService.navigateToNextLogin();
  return false;
};
