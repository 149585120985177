<div
  *ngIf="chatState !== ChatStates.Closed"
  class="chatbot-container"
  [hidden]="chatState === ChatStates.Minimized"
  [ngClass]="{ open: chatState === ChatStates.Opened }"
  [@chatWindowAnimation]
>
  <ni-chatbot></ni-chatbot>
</div>

<div class="mask" [ngClass]="{ open: chatState === ChatStates.Opened }"></div>

<ng-container *ngIf="config?.buttonConfig?.visible">
  <div class="chat-button-close" *ngIf="chatState === ChatStates.Closed" (click)="chatButtonClicked()" data-test="chat-button">
    <img src="/assets/chatbot/chat-icon.svg" alt="" data-test="chat-button-icon" class="chat-button-icon" />
    <span class="chat-button-text">{{ config.buttonConfig.text }}</span>
  </div>

  <div class="chat-button-minimize" *ngIf="chatState === ChatStates.Minimized" (click)="chatButtonClicked()" data-test="minimized-chat">
    <div class="left">
      <img src="/assets/chatbot/chat-icon.svg" alt="" data-test="chat-button-icon" class="chat-button-icon" />
      <span class="p-md">{{ config.buttonConfig.minimizedText }}</span>
    </div>
    <div class="right">
      <img src="/assets/chatbot/arrow-up.svg" alt="expand" />
      <img src="/assets/chatbot/cross.svg" alt="close" data-test="chat-button-close" (click)="closeChatbot()" />
    </div>
  </div>
</ng-container>
