import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import logger from '@next-insurance/logger';
import { WINDOW } from '@next-insurance/ng-core';
import { Store } from '@ngrx/store';
import { addHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { NEVER } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { EnvConfig } from '../../../environments/env.config';
import { environment } from '../../../environments/environment';
import { AuthErrorStatus, AuthErrorStatuses } from '../../login/models/auth-error-status.enum';
import { Auth0ErrorStatus } from '../../login/models/auth0-error-status.enum';
import { LoginTrackingService } from '../../login/services/login-tracking.service';
import { LoginV2DataService } from '../../login/services/login-v2.data.service';
import { loginActions } from '../../login/store/login.actions';
import { AppState } from '../../store';
import { navigationActions } from '../../store/navigation.actions';
import { MobileAppEventId } from '../models/mobile-app-event.model';
import { NextLoginReturnUrl, nextLoginReturnUrlKeyName } from '../models/next-login-state.model';
import { SessionStorageParams } from '../models/session-storage-params.enum';
import { SESSION_STORAGE } from '../tokens/session-storage.token';
import { Auth0UtilsService } from './auth0-utils.service';
import { MobileAppService } from './mobile-app.service';
import { MobileAppEventsService } from './mobile-app-events.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class PortalAuthService {
  private loginDataServiceV2 = inject(LoginV2DataService);
  private loginTrackingService = inject(LoginTrackingService);
  private auth0UtilsService = inject(Auth0UtilsService);
  private navigationService = inject(NavigationService);
  private mobileAppService = inject(MobileAppService);
  private mobileAppEventsService = inject(MobileAppEventsService);
  private store = inject(Store<AppState>);
  private cookieService = inject(CookieService);
  private storage: Storage = inject(SESSION_STORAGE);
  private window = inject(WINDOW);

  logout(shouldSaveReturnUrl = false): void {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.Logout, closeWebview: true });
      return;
    }

    this.storage.setItem(SessionStorageParams.ShouldKeepTrackingId, 'true');
    this.loginTrackingService.trackNextLoginLogout();
    this.loginDataServiceV2.logout().subscribe(() => {
      if (shouldSaveReturnUrl) {
        this.saveReturnUrl();
      }
      const logoutUrl = this.auth0UtilsService.createLogoutURL();
      this.navigationService.navigateTo(logoutUrl);
    });
  }

  async navigateToNextLogin(): Promise<void> {
    if (this.mobileAppService.isMobileAppWebview()) {
      this.mobileAppEventsService.notifyMobile({ eventId: MobileAppEventId.Logout, closeWebview: true });
      return;
    }

    this.storage.setItem(SessionStorageParams.ShouldKeepTrackingId, 'true');
    const loginUrl = await this.auth0UtilsService.createLoginURL();
    this.loginTrackingService.trackNavigateToNextLogin(loginUrl);
    this.navigationService.navigateTo(loginUrl);
  }

  handleCallback(auth0QueryParams: Params): void {
    const params = new URLSearchParams(auth0QueryParams);
    const errorId = params.get('error') as Auth0ErrorStatus;
    if (errorId) {
      const errorDescription = params.get('error_description');
      this.handleAuth0Error(errorId, errorDescription);
      return;
    }

    this.loginTrackingService.trackNextLoginCallbackStart(params.toString());
    const code = params.get('code');
    this.loginDataServiceV2
      .exchangeCodeForTokens(code)
      .pipe(
        tap(() => {
          this.navigateAfterSuccessfulLogin();
        }),
        // eslint-disable-next-line @next-insurance/catch-and-throw
        catchError((callbackError: HttpErrorResponse) => {
          this.handleExchangeCodeError(callbackError);
          return NEVER;
        }),
        finalize(() => {
          this.storage.removeItem(SessionStorageParams.ShouldKeepTrackingId);
        }),
      )
      .subscribe();
  }

  handleMlTokenFailure(errorReason: AuthErrorStatuses): void {
    this.loginTrackingService.trackMlTokenLoginFailure(errorReason);
    this.navigateToLoginErrorPage(errorReason);
  }

  private saveReturnUrl(): void {
    const nextLoginState: NextLoginReturnUrl = {
      returnUrl: this.window.location.pathname,
      queryParams: this.window.location.search || '',
    };

    this.storage.setItem(nextLoginReturnUrlKeyName, JSON.stringify(nextLoginState));
  }

  private navigateAfterSuccessfulLogin(): void {
    const returnUrlStorageValue = this.storage.getItem(nextLoginReturnUrlKeyName);

    if (returnUrlStorageValue) {
      const { returnUrl, queryParams } = JSON.parse(returnUrlStorageValue) as NextLoginReturnUrl;
      this.storage.removeItem(nextLoginReturnUrlKeyName);
      this.navigationService.navigateTo(returnUrl + queryParams);
    } else {
      this.store.dispatch(navigationActions.toHomePage({}));
    }
  }

  private handleExchangeCodeError(error: HttpErrorResponse): void {
    const errorReasonFromBE: AuthErrorStatuses = error.error?.niStatusCode || AuthErrorStatuses.InternalFailure;
    this.loginTrackingService.trackNextLoginExchangeCodeError(errorReasonFromBE);
    logger.error('[Next Login] Error while exchanging code for tokens', error, { errorReasonFromBE });

    this.navigateToLoginErrorPage(errorReasonFromBE);
  }

  private handleAuth0Error(errorStatus: Auth0ErrorStatus, errorDescription: string): void {
    this.loginTrackingService.trackNextLoginCallbackAuth0Error(errorStatus, errorDescription);
    logger.error('[Next Login] Received an error from Auth0 in the callback as a query parameter', null, {
      errorStatus,
      errorDescription,
    });

    this.navigateToLoginErrorPage(errorStatus);
  }

  private navigateToLoginErrorPage(errorStatus: AuthErrorStatus): void {
    const oneHourExpiration = addHours(Date.now(), 1);
    this.cookieService.set(environment.loginErrorStatusCookieName, errorStatus, {
      domain: this.getHostDomain(),
      expires: oneHourExpiration,
      secure: true,
      path: '/',
    });
    this.store.dispatch(loginActions.logout());
  }

  private getHostDomain(): string {
    return EnvConfig.isDev() ? environment.tld : `.${environment.tld}`;
  }
}
