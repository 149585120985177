<div class="input-background" *ngIf="exposed" [@textInputAnimation]>
  <div class="user-message-input-border" [ngClass]="{ invalid: invalidLength }">
    <div
      #userMessageInput
      class="user-message-input content-editable"
      id="user-message-input"
      data-test="user-message-input"
      niFocus
      contentEditable="true"
      data-placeholder="Type a message"
      (keydown.enter)="sendMessage($event)"
      niAutoGrow
      niContentEditable
      maxHeightBeforeScroll="147"
    ></div>
  </div>
  <div class="text-field-helper-line">
    <span *ngIf="invalidLength" class="error-label" data-test="error-label">Max characters allowed is {{ DF_MESSAGE_MAX_LENGTH }}</span>
    <div *ngIf="showCharCounter" class="text-field-character-counter" data-test="char-counter">{{ characterCounterText }}</div>
  </div>
  <fa-icon
    class="btn-send"
    data-test="btn-send-message-icon"
    (click)="sendMessage($event)"
    [ngClass]="{
      'with-char-counter': showCharCounter,
      'btn-disabled': isMessageInvalid(),
    }"
    [styles]="{
      'background-color': 'white',
      'font-size': '26px',
      color: isMessageInvalid() ? 'rgba(25, 114, 235, 0.4)' : 'rgba(25, 114, 235, 1)',
      'border-radius': '100%',
    }"
    [icon]="faArrowCircleUp"
  ></fa-icon>
</div>
